import React, { useState, useRef, useEffect } from "react"
import AWS from "aws-sdk"
import { QRCodeSVG } from "qrcode.react"
import Layout from "../../components/layout"
import {
  Box,
  Stack,
  Modal,
  Grid,
  Link,
  CircularProgress,
  Typography,
  Container,
  Button,
} from "@mui/material"

import * as THREE from "three"
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment.js"

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"

import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter.js"

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"

import suitCaseFile from "../../assets/models/suitcase2.glb"

import ImageCard from "../../components/demo/imageCard"

import image1 from "../../assets/images/suitcases/11-graphic-design-trends-for-2021.-Graphic-design-by-BATHI-on-99designs.jpg"

import image2 from "../../assets/images/suitcases/a.jpg"
import image3 from "../../assets/images/suitcases/20220208122730_flags_of_the_world_book_and_jigsaw.jpg"
import image4 from "../../assets/images/suitcases/nft.jpg"
import greenColor from "../../assets/images/suitcases/green.jpg"

import redColor from "../../assets/images/suitcases/red.jpg"

import blueColor from "../../assets/images/suitcases/blue.jpg"

import yellowColor from "../../assets/images/suitcases/yellow2.jpg"

import uploadColor from "../../assets/images/suitcases/upload.jpg"

const colorsData = [
  {
    image: image1,
  },
  {
    image: image2,
  },
  {
    image: image3,
  },
  {
    image: image4,
  },
  {
    image: greenColor,
  },
  {
    image: redColor,
  },
  {
    image: blueColor,
  },
  {
    image: yellowColor,
  },
  // {
  //   image: uploadColor,
  // },
]

const SuitCase = () => {
  const canvasRef = useRef()

  const [previewOpen, setPreviewOpen] = React.useState(false)
  const [previewUploading, setPreviewUploading] = React.useState(0)

  const [previewID, setPreviewID] = React.useState()

  const [canUpload, setCanUpload] = React.useState(true)

  const handleClose = () => {
    setPreviewOpen(false)
    setCanUpload(true)
  }

  AWS.config.update({
    accessKeyId: "AKIARZ5RIYTJMCVGF5MO",
    secretAccessKey: "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",
  })

  AWS.config.getCredentials(function (err) {
    if (err) console.log(err.stack)
    // credentials not loaded
    else {
      // console.log("Access key:", AWS.config.credentials.accessKeyId);
    }
  })

  let S3 = new AWS.S3({
    params: { Bucket: "ar-tool-preview" },
    region: "us-east-2",
  })

  let clickAction = () => {}
  // const clickE

  useEffect(() => {
    let linkId = Date.now()
    const exporter = new GLTFExporter()
    const textureFileReader = new FileReader()

    const canavs = canvasRef.current
    const canvasRoot = canavs.parentNode

    var scene = new THREE.Scene()
    var camera = new THREE.PerspectiveCamera(
      50,
      canavs.offsetWidth / canavs.offsetHeight,
      0.01,
      100
    )

    let coverMaterial

    camera.position.set(0.3, 1, 1.5)
    var renderer = new THREE.WebGLRenderer({
      alpha: true,
      canvas: canavs,
      antialias: true,
      preserveDrawingBuffer: true,
    })
    renderer.setClearColor(0xffffff, 0)

    renderer.toneMapping = THREE.LinearToneMapping
    renderer.outputEncoding = THREE.sRGBEncoding

    renderer.setSize(canavs.offsetWidth, canavs.offsetHeight)

    renderer.toneMappingExposure = 0.85
    const pmremGenerator = new THREE.PMREMGenerator(renderer)
    pmremGenerator.compileEquirectangularShader()
    scene.environment = pmremGenerator.fromScene(
      new RoomEnvironment(),
      0.04
    ).texture

    const controls = new OrbitControls(camera, renderer.domElement)
    controls.enablePan = false
    controls.enableDamping = true
    controls.target.set(0, 0.5, 0)

    clickAction = e => {
      console.log(e)
    }

    textureFileReader.onload = e => {
      const loadFIle = textureFileReader.result

      const texture = new THREE.TextureLoader().load(loadFIle)
      texture.flipY = false
      texture.encoding = THREE.sRGBEncoding

      coverMaterial.map = texture
    }

    window.addEventListener("resize", onWindowResize, false)

    const loader = new GLTFLoader()

    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath("../../draco/")
    dracoLoader.setDecoderConfig({ type: "js" })

    loader.setDRACOLoader(dracoLoader)

    loader.load(
      suitCaseFile,
      gltf => {
        scene.add(gltf.scene)
        const scale = 1
        gltf.scene.scale.set(scale, scale, scale)
        gltf.scene.rotation.set(0, Math.PI, 0)
        coverMaterial = gltf.scene.getObjectByName("shlow_side_1").material
        onGltfLoad()
      },
      function (xhr) {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded")
      },
      // called when loading has errors
      function (error) {
        console.log("An error happened")
      }
    )

    function onGltfLoad() {
      document.querySelectorAll(".colors").forEach(el => {
        el.addEventListener("click", e => {
          console.log(el.dataset.color)
          const url = el.dataset.color

          const texture = new THREE.TextureLoader().load(url)
          texture.flipY = false
          texture.encoding = THREE.sRGBEncoding

          coverMaterial.map = texture
        })
      })

      document.querySelector("#logo-upload").addEventListener("change", e => {
        // console.log(e);
        textureFileReader.readAsDataURL(e.target.files[0])
      })

      document.querySelector("#preview-btn").addEventListener("click", () => {
        if (canUpload) {
          setCanUpload(false)
          checkKeyExisit()
        }
      })
    }

    const checkKeyExisit = () => {
      const key = `${linkId}.png`

      const params = {
        Bucket: "ar-tool-preview/previews",
        Key: key,
      }
      S3.headObject(params, function (err, data) {
        console.log({ data, err })

        if (data) {
          console.log({ yes: linkId })
          linkId = Date.now()
          console.log({ new: linkId })
          checkKeyExisit()
        }

        if (err) {
          console.log({ none: linkId })
          renderPreviewAndSave()
        }
      })
    }

    const renderPreviewAndSave = async () => {
      setPreviewID(linkId)
      setPreviewOpen(true)
      uploadPoster()
      uploadGltf()
    }

    const uploadPoster = () => {
      let file = null

      console.log(canavs)

      // const canvas = document.querySelector("#custom-canvas")

      // const img = canvas.toDataURL('image/png')
      // var dlLink = document.createElement('a');
      // dlLink.download = "text.png";
      // dlLink.href = img;
      // dlLink.click();

      canavs.toBlob(function (blob) {
        file = new File([blob], `${linkId}.png`, { type: "image/png" })

        const key = `${linkId}.png`

        const params = {
          Body: file,
          Bucket: "ar-tool-preview/previews",
          Key: key,
        }

        S3.putObject(params)
          .on("httpUploadProgress", evt => {
            console.log(Math.round((evt.loaded / evt.total) * 100))
          })
          .send((err, data) => {
            if (err) console.log({ err })
          })
      }, "image/png")
    }

    const uploadGltf = () => {
      const gltfParams = {
        trs: false,
        onlyVisible: true,
        truncateDrawRange: true,
        binary: true,
      }

      exporter.parse(
        scene,
        // called when the gltf has been generated
        function (result) {
          // const output = JSON.stringify(result, null, 2); // gltf
          const output = result // glb
          const blob = new Blob([output], { type: "text/plain" })

          let glbFile = blob

          const params = {
            Body: glbFile,
            Bucket: "ar-tool-preview/previews",
            Key: `${linkId}.glb`,
          }

          S3.putObject(params)
            .on("httpUploadProgress", evt => {
              const amount = Math.round((evt.loaded / evt.total) * 100)
              console.log("uploading to preview " + amount)
              setPreviewUploading(amount)
              // setUploading(amount)
            })
            .send((err, data) => {
              if (err) console.log({ err })

              // if (data) setPreviewID(previewID);
            })
        },

        // called when there is an error in the generation
        function (error) {
          console.log("An error happened")
        },
        gltfParams
      )
    }

    function onWindowResize() {
      console.dir(canavs)
      camera.aspect = canvasRoot.clientWidth / canvasRoot.clientHeight
      camera.updateProjectionMatrix()
      renderer.setSize(canvasRoot.clientWidth, canvasRoot.clientHeight)
    }

    var animate = function () {
      requestAnimationFrame(animate)
      controls.update()
      renderer.render(scene, camera)
    }

    animate()
  }, [])

  return (
    <Layout>
      <Container
        disableGutters
        sx={{
          backgroundColor: "white",
        }}
      >
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontFamily: "clash display, sans-serif",
            fontSize: { xs: "1.5rem", md: "3rem" },
            color: "black",
            textAlign: "center",
            lineHeight: "150%",
            paddingTop: "130px",
            paddingBottom: "30px",
            fontWeight: "600",
            justifyContent: "center",
          }}
        >
          Custom 3D configuration and WebAR
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          Enable more interactive Product for e-commerce
        </Typography>
      </Container>
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            padding: "100px 0",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: "500px",
            }}
          >
            <Box
              ref={canvasRef}
              component="canvas"
              id="custom-canvas"
              sx={{
                cursor: "grab",
                border: "1px solid #8080801c",
                width: "100%",
                height: "100%",
                backgroundColor: "grey",
                backgroundSize: "contain",
              }}
            ></Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: "500px",
              padding: "20px",
              paddingLeft: "35px !important",
              color: "black",
            }}
          >
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{
                fontFamily: "clash display, sans-serif",
              }}
            >
              The Carry-On
            </Typography>
            <Typography variant="h6" component="div">
              The lightest, strongest carry-on you will ever find—only 7.3 lbs
              and fits the carry-on requirements of all major U.S. airlines.
              Note: the Expandable version does not officially qualify as a
              carry-on, when expanded.
            </Typography>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{
                fontFamily: "clash display, sans-serif",
              }}
            >
              Colors
            </Typography>
            <Box>
              <Box sx={{}}>
                {colorsData.map((data, index) => (
                  <ImageCard
                    name={"colors"}
                    key={index}
                    image={data.image}
                  ></ImageCard>
                  // <Card
                  //   key={index}
                  //   sx={{
                  //     cursor: "pointer",
                  //     padding: 0,
                  //     maxWidth: 70,
                  //     maxHeight: 70,
                  //     overflow: "hidden",
                  //   }}
                  // >
                  //   <CardActions className="colors"  data-color={data.image} onClick={clickAction} sx={{ padding: 0 }}>
                  //     <CardMedia
                  //       component="img"
                  //       alt="green iguana"
                  //       height="140"
                  //       image={data.image}
                  //     />
                  //   </CardActions>
                  // </Card>
                ))}
                <Box
                  sx={{
                    display: "inline-block",
                  }}
                >
                  <input
                    type="file"
                    id="logo-upload"
                    accept="image/png"
                    style={{ display: "none" }}
                  />
                  <label
                    htmlFor="logo-upload"
                    style={{
                      display: "inline-block",
                      width: "50px",
                      height: "50px",
                      backgroundImage: `url(${uploadColor})`,
                      backgroundSize: "contain",
                      borderRadius: "5px",
                      cursor: "pointer",
                      margin: "5px 10px",
                      boxShadow: "rgb(136 136 136 / 72%) 4px 6px 8px 0px",
                      border: "1px solid gray",
                    }}
                  ></label>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "50px",
              }}
            >
              <Button id="preview-btn" variant="contained">
                Generate AR link and Share
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Modal
        open={previewOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {previewUploading < 100 ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              gap: "10px",
              width: 430,
              height: 300,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <CircularProgress />
            <Typography
              sx={{
                fontFamily: "clash display, sans-serif",
                color: "black",
              }}
            >{`${previewUploading}%`}</Typography>

            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontFamily: "clash display, sans-serif",
                color: "black",
              }}
            >
              Making a Web AR link. Please wait...
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontFamily: "clash display, sans-serif",
                color: "black",
              }}
            >
              Congratulation! you link is ready
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              sx={{
                alignItems: "center",
                gap: "20px",
                color: "black",
              }}
            >
              <QRCodeSVG
                value={`https://ar-tool-preview.s3.dualstack.us-east-2.amazonaws.com/neoxr-preview.html?preview=${previewID}`}
                size="300"
              />
              <img
                style={{
                  height: "330px",
                }}
                src={`https://ar-tool-preview.s3.us-east-2.amazonaws.com/previews/${previewID}.png`}
              ></img>
            </Stack>
            <Link
              href={`https://ar-tool-preview.s3.dualstack.us-east-2.amazonaws.com/neoxr-preview.html?preview=${previewID}`}
              underline="always"
              target="_blank"
              sx={{
                padding: "12px",
                textAlign: "center",
                width: "50%",
              }}
            >
              {`https://ar-tool-preview.s3.dualstack.us-east-2.amazonaws.com/neoxr-preview.html?preview=${previewID}`}
            </Link>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Share this link with your fiend, client, customers, or anyone. and
              open with Safari or Chrome.
            </Typography>

            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Box>
        )}
      </Modal>
    </Layout>
  )
}

export default SuitCase
