import React from "react"

import { Box, Button, Grid, Avatar } from "@mui/material"

const ImageCard = props => {
  return (
    <React.Fragment>
      <Box
        className={props.name}
        id = {props.id}
        style={{
          width: "50px",
          height: "50px",
          backgroundImage: `url(${props.image})`,
          backgroundSize: "contain",
          borderRadius: "5px",
          cursor: "pointer",
          display: "inline-block",
          margin: "5px 10px",
          boxShadow: "rgb(136 136 136 / 72%) 4px 6px 8px 0px",
          border: "1px solid gray",
        }}
        data-color={props.image}
      ></Box>
    </React.Fragment>
  )
}

export default ImageCard
